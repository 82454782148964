
import { axiosApiInstance } from '../common/axios'
const LOGIN_PATH = '/api/users/login'
const RESET_PASSWORD_PATH = (cvIdentifier: string) => `/api/user/${cvIdentifier}/password`
const REQUEST_RESET_PASSWORD_PATH = (cvIdentifier: string) => `/api/user/${cvIdentifier}/password/reset`

const headers = {
    'Content-Type': 'application/json',
}

export const postLogin = async (username: string, password: string) => {
    return await axiosApiInstance.post(
        LOGIN_PATH,
        JSON.stringify({ username, password }),)
}

export const postPasswordReset = async (passwordResetId: string, username: string, password: string) => {
    return await axiosApiInstance.post(RESET_PASSWORD_PATH(username), JSON.stringify({ passwordResetId, password }))
}

export const getPasswordReset = async (username: string) => {
    return await axiosApiInstance.get(REQUEST_RESET_PASSWORD_PATH(username))
}

export default {
    postLogin,
    getPasswordReset,
    postPasswordReset,
}