export default () => ({
  LandingPageAboveFold: {
    features: 'VARFÖR ACTORCV?',
    example: 'EXEMPEL',
    'try.free': 'PROVA GRATIS',
    'create.your.own': 'Skapa din egen',
    'actors.website': 'Skådishemsida',
    'set.up.your': 'Bygg din hemsida på bara 15 minuter.',
    'designed.in.collaboration': 'Framtaget i samarbete med professionella castare.',
  },
  StartSignUpForm: {
    'please.provide.a.name': 'Välj ett namn för din sida.',
    'please.choose.a.page': 'Du måste välja ett namn som inte är upptaget.',
    'its.available': 'Namnet är ledigt!',
    'already.taken.sorry': 'Redan paxat, tyärr!',
    'sign.up': 'PROVA GRATIS',
    'your.name': 'Skriv ditt namn',
    'do.you.already': 'Har du redan ett konto hos oss?',
  },
  Benefits: {
    why: 'Varför ACTORCV?',
    'simple.as.can': 'Så enkelt det kan bli',
    'setting.up.your.page':
      'Det går supersnabbt att skapa din sida. Ladda upp några bilder, länka några showreels och knappa in dina erfarenheter.',
    'casters.love.it': 'Castare älskar det',
    'we.have.developed.the.prooduct': 'Vi har utvecklat cv-mallen i sammarbete med castare.',
    'share.with.ease': 'Dela enkelt',
    'create.your.own': 'Skapa din egen sida och dela sedan enkelt länken.',
    'not.technical': 'INTE TEKNISKT',
    'dont.get.caught.up':
      'Du behöver inte kunna tekniken. Vi löser det åt dig. Allt är anpassat efter hur castare vill ha det.',
    'no.decisions': 'INGA BESLUT',
    'dont.spend.time.thinking': 'Du behöver inte ta tusen beslut, vi har redan gjort det åt dig.',
    'not.a.handful': 'INGET STRUL',
    'sharing.and.managing.heavy':
      'Att dela och hålla reda på en massa tunga filer i olika format är jobbigt. Låt oss göra det.',
  },
  Story: {
    'great.for.everyone': 'Om oss',
    'actorcv.was.created.by.actors':
      'ActorCV skapades av skådespelare för skådespelare. Vi insåg vilket problem det var att inte ha en sida att enkelt marknadsföra oss själva som skådespelare. Så vi skapade ActorCV!',
    'we.spent.way.too.long':
      'Alldeles för länge skickade vi PDFer till agenter och regisörer och förstod inte hur osmidigt det var. Nu är det enklare och proffsigare för alla.',
    'we.would.love.to.hear':
      'Vi hör gärna ifrån er! Oavsett vad ni har att säga så uppskattar vi det. Vi letar hela tiden efter saker att förbättra.',
  },
  ControlPanelPage: {
    'edit.your.page': 'ÄNDRA DIN SIDA',
    'there.is.a.problem': 'Just nu är det bara du som kan se din sida.',
    'go.to': 'Gå till',
    'to.resolve': 'för att lösa problemet.',
    account: 'KONTO',
  },
  EditCvForm: {
    'you.can.not': 'Du kan bara ha en introduktion på max 255 tecken.',
    'personal.information': 'Personlig information',
    'first.name': 'Förnamn',
    'last.name': 'Efternamn',
    city: 'Stad',
    country: 'Land',
    email: 'Epost',
    phone: 'Telefon',
    'date.of.birth': 'Födelsedatum',
    introduction: 'Presentation',
    edit: 'ÄNDRA',
    'hide.my.email': 'Dölj min epost på min publika sida.',
    save: 'SPARA',
  },
  PhotoManagement: {
    photos: 'FOTON',
    'add.photo': 'Lägg till foto',
    delete: 'RADERA',
    reorder: 'ORDNA',
    'select.hero': 'VÄLJ',
    done: 'KLART',
  },
  ListPhotoForm: {
    rearrange: 'ORDNA',
    photos: 'FOTON',
    videos: 'VIDEOS',
    save: 'SPARA',
  },
  AddPhotoForm: {
    'select.file': 'VÄLJ FIL',
  },
  VideoManagement: {
    videos: 'FILMER',
    'videos.are.playable': '*filmer är spelbara i CV-läget',
    delete: 'RADERA',
    reorder: 'ORDNA',
    done: 'KLART',
  },
  AddVideoForm: {
    'only.links.from': 'Enbart länkar från youtube och vimeo är tillåtna.',
    'add.video': 'Lägg till film',
    link: 'LÄNK',
    add: 'LÄGG TILL',
  },
  ExperienceManagement: {
    title: 'RESUME',
    delete: 'RADERA',
    reorder: 'ORDNA',
    edit: 'ÄNDRA',
    done: 'KLART',
  },
  AddExperienceForm: {
    'you.need.to': 'Du måste ange något i åtminstone ett fält för att kunna spara.',
    'add.resume': 'Lägg till resume',
    experience: 'Erfarenhet',
    category: 'Kategori',
    'category.title': 'Kategorititel',
    year: 'ÅR',
    movies: 'Filmer',
    'project.example': 'En bra film',
    project: 'PROJECT',
    'character.example': 'Batman eller Lead/Supporting',
    character: 'ROLL',
    'director.example': 'Regi av Laila Smith',
    director: 'REGISSÖR',
    'production.company.example': 'Bra Film Produktion',
    'production.company': 'PRODUKTIONSBOLAG',
    add: 'LÄGG TILL',
  },
  EditExperienceForm: {
    title: 'Titel',
    year: 'År',
    project: 'Project',
    role: 'Roll',
    director: 'Regissör',
    'production.company': 'Produktionsbolag',
    save: 'SPARA',
  },
  AccountPage: {
    account: 'KONTO',
    'acv.profile': 'Acv sida',
    username: 'Användarnamn',
    'user.created': 'Användare skapad',
    subscription: 'Abonnemang',
    '30d.free.ends.in': '30 dagar gratis. Slutar om',
    days: 'dagar',
    'subscription.required.since': 'Abonnemang krävs sedan',
    'your.subscription.is.payed': 'Ditt abonnemang är betalt och din sida är synlig.',
    'your.profile.is.temporarily.suspended': 'Ditt konto är tillfälligt avstängt för att vi saknar din betalning.',
    'your.free.plan.is.up': 'Du behöver skaffa ett abonnemang. Din sida är inte synlig.',
    'subscription.activated': 'Abonnemang startat. Din sida är synlig.',
    'subscription.sign.up.canceled': 'Köpa abonnemang avbrutet. Din sida är inte synlig.',
    'log.out': 'LOGGA UT',
    'your.account.is.free.until': 'Din testperiod gäller till',
    'your.profile.is.visible': 'och din sida är synlig.',
    'visit.cutomer.portal':
      'För att handera abonnemang, see kvitton och betalningar etc. besök vår kundportal hos Stripe.',
  },
  CheckoutForm: {
    'select.a.subscription.to.keep':
      'Starta ett abonnemang för att behålla din sida synlig efter att gratisperioden är slut.',
    'select.a.subscription.to.enable': 'Starta ett abonnemang för att göra din sida synlig igen.',
    'monthly.sub': 'Månadsabonnemang',
    monthly: '/månad',
    '3usd': '3',
    '.90usd': '99',
    'monthly.details': 'Faktureras varje månad. Avbryt närsomhelst.',
    'yearly.sub': 'Årsabonnemang',
    yearly: '/år',
    '39usd': '37',
    '.00usd': '00',
    'yearly.details': 'Faktureras årligen. Avbryt närsomhelst.',
    'yearly.discount': '20% RABATT!',
    subscribe: 'STARTA',
  },
  CustomerPortalForm: {
    change: 'Stripe kundportal',
  },
  LoginPage: {
    title: 'logga in',
    'forgot.password': 'Glömt lösenordet?',
  },
  LoginForm: {
    'please.provide': 'Ange lösenord och användarnamn.',
    email: 'Email',
    password: 'Lösenord',
    'log.in': 'LOGGA IN',
  },
  SignUpPage: {
    'your.email.link': 'Varsågod, här har du länken till din epost!',
    'sign.up.success': 'REGISTRERING LYCKADES',
    'sign.up.error': 'REGISTRERING MISSLYCKADES',
    'something.went.wrong':
      'Något gick fel när vi försökte registrera ditt konto. Kontakta support eller försök igen senare.',
    'activate.your.account':
      'Aktivera ditt konto genom att klicka på länken i e-mailet som skickats till dig. Om du inte hittar det så kolla din skräppost!',
    'sign.up': 'REGISTRERA',
    'by.signing.up': 'Genom att registrera dig så går du med på vår',
    'terms.and.conditions': 'Generella villkor',
    and: 'och vår',
    'privacy.policy': 'Integritetspolicy',
  },
  SignUpForm: {
    email: 'Email',
    password: 'Lösenord',
    'repeat.password': 'Repetera lösenord',
    'sign.up.free': 'REGISTRERA',
    'please.choose.your.password': 'Välj ett lösenord.',
    'passwords.do.not.match': 'Lösenorden är olika.',
    'invalid.email': 'Ogitlig emailadress.',
  },
  RequestPasswordResetPage: {
    'reset.password': 'Återställ lösenord',
    'reset.password.success': 'Begäran om lösenordsåterställning beställd',
    'reset.password.success.info':
      'Om adressen är knuten till ett konto hos oss kommer du att få ett mail med instruktioner hur du byter ditt lösenord.',
    'reset.password.error': 'Begäran om lösenordsåterställning misslyckades',
  },
  RequestPasswordResetForm: {
    email: 'Email',
    submit: 'SKICKA',
    'please.enter': 'Ange din email för att återställa ditt lösenord.',
    'invalid.email': 'Ogitlig emailadress.',
  },
  VerifySignUpPage: {
    title: 'VERIFIERA KONTO',
    success: 'Konto verifierat! Du kommer att skickas vidare till loginsidan.',
    error: 'Ett fel uppstod när vi försökte verifiera kontot.',
  },
  Header: {
    admin: 'ADMIN',
    cv: 'CV',
    account: 'KONTO',
    'log.in': 'LOGGA IN',
    'edit.cv': 'ÄNDRA CV',
    'view.cv': 'VISA CV',
  },
  CvHeader: {
    'edit.cv': 'ÄNDRA CV',
    'view.cv': 'VISA CV',
    photos: 'FOTON',
    videos: 'VIDEOR',
    resume: 'RESUME',
  },
  ShowCvPersonalInfo: {
    'years.old': 'år',
  },
  ShowCvPhotos: {
    photos: 'FOTON',
  },
  ShowCvVideos: {
    videos: 'VIDEOR',
  },
  ShowCvExperience: {
    resume: 'RESUME',
  },
  CvError: {
    'we.are.sorry': 'Vi är mycket ledsna',
    'we.know.this.user':
      'Vi hittar användaren, men sidan är stängd för tillfället. Tala om att du fick dethär meddelandet så de kan fixa det!',
    'but.we.dont.know': 'Vi hittar inte användaren du letar efter.',
    'there.was.an.unexpected': 'Ett oväntat fel har skett, rapportera gärna till support om felet kvarstår.',
  },
  AdminPage: {
    admin: 'ADMIN',
    users: 'ANVÄNDARE',
    emails: 'EMAIL',
    'stripe.events': 'STRIPE',
  },
  Count: {
    users: 'ANVÄNDARE (TOTALT)',
    'users.today': 'ANVÄNDARE (IDAG)',
    'users.paid': 'BETALANDE ANVÄNDARE',
    emails: 'EMAIL (IDAG)',
  },
  RunScheduled: {
    run: 'KÖR PROCESS',
  },
  DataTablePaginated: {
    reset: 'ÅTERSTÄLL',
    name: 'Namn',
    email: 'Email',
    cv: 'Cv',
    photos: 'Foton',
    resume: 'Resume',
    videos: 'Videor',
    created: 'Skapad',
    delete: 'Radera',
    cvStatus: 'Cv status',
    stripeCustomerId: 'Stripe id',
    lastPaymentStatus: 'Betalstatus',
    plan: 'Plan',
    action: 'Åtgärd',

    userId: 'User Id',
    email: 'Email',
    template: 'Template',
    language: 'Language',
    created: 'Created',

    eventId: 'Event Id',
    customerId: 'Kund Id',
    processed: 'Processad',
  },
  UserStats: {
    'users.aquired': 'Förvärvade användare',
  },
  VerifyDeletePage: {
    'verify.delete': 'Verifiera borttag',
    'you.are.trying': 'Du försöker ta bort användaren',
    'this.action.will': 'Detta kommer att ta bort användaren och all dess data från ACV.',
    'type.the.username': 'Skriv in användarnamnet (email) nedan för att gå vidare.',
    delete: 'Radera',
    'deleted.user': 'Användare raderad.',
  },
  FilterComponent: {
    email: 'Email',
    reset: 'ÅTERSTÄLL',
  },
  PasswordResetForm: {
    password: 'Lösenord',
    'repeat.password': 'Repetera lösenord',
    submit: 'Skicka',
    'please.choose.password': 'Välj ditt nya lösenord.',
    'passwords.not.match': 'Lösenord måste vara lika.',
  },
  PasswordResetPage: {
    'reset.password': 'Återställ lösenord',
    'reset.password.success': 'Återställ lösenord lyckades',
    'reset.password.error': 'Återställ lösenord misslyckades',
  },
  EmailProviderLink: {
    'your.email.link': 'Varsågod, här har du länken till din epost!',
  },
  CookieConsentModalContent: {
    'cookie.consent': 'Cookies samtycke',
    'this.website': 'Denna site använder cookies för att säkerställa att du får bästa möjliga upplevelse.',
    'learn.more.about.cookies': 'Läs mer om cookies',
    'how.cookies': 'Hur vi använder cookies',
    'we.use.cookies': 'Vi använder cookies och du måste göra ett aktivt val vilka cookies du accepterar.',
    'accept.all.cookies': 'Acceptera alla cookies',
    'accept.selected.cookies': 'Acceptera valda cookies',
    'necessary.cookies': 'Nödvändiga cookies',
    'necessary.cookies.description':
      'Dessa cookies behövs för att sidan ska fungera och går inte att avböja. T.ex gör de så att du blir automatiskt inloggad på ditt konto.',
    'functionality.cookies': 'Funktions-cookies',
    'functionality.cookies.description':
      'Behövs för att komma ihåg vilka val du gjort för att sidan skall fungera bättre. T.ex komma ihåg din språkinställning.',
    'performance.cookies': 'Prestanda-cookies',
    'performance.cookies.description':
      'Används för att samla in data om hur besökare använder sidan. Detta för att kunna förbättra siten. Vi använder Hotjar för att mäta användbarhet.',
    'marketing.cookies': 'Marknadsförings-cookies',
    'marketing.cookies.description':
      'Dessa är framförallt från tredjepartstjänsterna, Youtube och Vimeo som används för att visa videos på skådissidorna. Se deras respektive cookie policies för uppdaterad information om hur de används.',
  },
  CookiesConsentConfirmationModalContent: {
    'you.have.chosen': 'Du väljer bort funktionalitet',
    'through.declining':
      'Genom att avböja kakor för funktionalitet kommer du inte kunna använda vissa funktioner på webbplatsen. Detta kan påverka din användarupplevelse.',
    'functionality.cookies':
      'Du har valt bort Funktions-cookies. Det innebär att dina språkinställningar kan komma att fungera sämre.',
    'marketing.cookies':
      'Du har valt bort Marknadsförings-cookies. Det innebär att vi inte kan visa något videoinnehåll på sites.',
    'our.advice': 'Vårt råd är att du accepterar alla kakor för att få den bästa upplevelsen och alla funktioner.',
    change: 'ÄNDRA MITT VAL AV COOKIES',
    accept: 'GÅ VIDARE MED VALDA COOKIES',
  },
  VideoDisabled: {
    'we.sorry': 'Vi är ledsna',
    'you.have.disabled':
      'Du har stängt av video-funktionen genom att inte acceptera marknadsförings-cookies. För att kunna se videos måste du acceptera dessa cookies.',
    'review.consent': 'ÄNDRA COOKIES MEDGIVANDE',
  },
})
