import React, { ReactElement, useEffect, useState } from 'react'
import withI18n, { WithI18nProps } from '../common/i18n/withI18n'
import Checkbox from '../common/Checkbox'
import { State } from '../../types'
import { useSelector } from 'react-redux'
import { saveCookiesConsent } from './userSlice'
import { useAppDispatch } from '../../hooks/useAppState'
import { selectIsAuthenticated } from '../auth/authSlice'
import { readCookiesConsentFromLocalStorage } from './CookiesConsentUtil'
import Modal from '../common/Modal'
import CookiesConsentConfirmationModalContent from './CookiesConsentConfirmationModalContent'
import { is } from 'cypress/types/bluebird'

export interface CookieConsentModalContentProps extends WithI18nProps {}

export interface CookiesConsent {
  cookiesConsentCollected: boolean
  functionalityCookies: boolean
  performanceCookies: boolean
  marketingCookies: boolean
}

function CookieConsentModalContent({ t }: CookieConsentModalContentProps): ReactElement {
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const remoteCookieConsent = useSelector((state: State) => state.user.value?.cookiesConsentCollected)
  const changeCookiesConsent = useSelector((state: State) => state.common.changeCookiesConsent)
  const [displayCookieConsentModal, setDisplayCookieConsentModal] = useState<boolean>(false)
  const [cookiesConsent, setCookiesConsent] = useState<CookiesConsent>({
    cookiesConsentCollected: false,
    functionalityCookies: false,
    performanceCookies: false,
    marketingCookies: false,
  })
  const dispatch = useAppDispatch()
  const [showSubModal, setShowSubModal] = useState<boolean>(false)

  useEffect(() => {
    const storedCookiesConsent: CookiesConsent | null = readCookiesConsentFromLocalStorage()
    const localCookieConsent = storedCookiesConsent?.cookiesConsentCollected
    console.log('CookieConsentModalContent', {
      changeCookiesConsent,
      isAuthenticated,
      localCookieConsent,
      remoteCookieConsent,
    })
    if (changeCookiesConsent && isAuthenticated) {
      console.log('CookieConsentModalContent - Case 0')
      setDisplayCookieConsentModal(true)
      return
    } else if (localCookieConsent && !isAuthenticated) {
      console.log('CookieConsentModalContent - Case 1')
      // Nothing to do
      setDisplayCookieConsentModal(false)
      dispatch(saveCookiesConsent({ cookiesConsent: storedCookiesConsent, isAuthenticated }))
      return
    } else if (localCookieConsent && remoteCookieConsent && isAuthenticated) {
      console.log('CookieConsentModalContent - Case 2')
      // Nothing to do
      setDisplayCookieConsentModal(false)
      dispatch(saveCookiesConsent({ cookiesConsent: storedCookiesConsent, isAuthenticated }))
      return
    }
    if (localCookieConsent && !remoteCookieConsent && isAuthenticated) {
      console.log('CookieConsentModalContent - Case 3')
      // Save to db
      dispatch(
        saveCookiesConsent({ cookiesConsent: { ...cookiesConsent, cookiesConsentCollected: true }, isAuthenticated })
      )
    } else if (!storedCookiesConsent && remoteCookieConsent && isAuthenticated) {
      console.log('CookieConsentModalContent - Case 4')
      // Save to local storage
      dispatch(
        saveCookiesConsent({ cookiesConsent: { ...cookiesConsent, cookiesConsentCollected: true }, isAuthenticated })
      )
    } else if (!remoteCookieConsent) {
      console.log('CookieConsentModalContent - Case 5')
      // Collect it
      setDisplayCookieConsentModal(true)
    }
  }, [cookiesConsent, remoteCookieConsent, isAuthenticated])

  const onSubmit = async (cookiesConsentArg: CookiesConsent) => {
    const cookiesConsentToSave = {
      cookiesConsent: { ...cookiesConsentArg, cookiesConsentCollected: true },
      isAuthenticated,
    }
    await dispatch(saveCookiesConsent(cookiesConsentToSave))
    window.location.reload()
  }

  const modal = (
    <div className="cookie-consent-modal-content">
      <h1>{t('cookie.consent')}</h1>
      <p>{t('this.website')}</p>
      <p>
        <a href="https://www.cookiesandyou.com/" target="_blank" rel="noopener noreferrer">
          {t('learn.more.about.cookies')}
        </a>
      </p>

      <h2>{t('how.cookies')}</h2>
      <p>{t('we.use.cookies')}</p>
      <p className="cookie-selection">
        <Checkbox
          id="necessary"
          name="necessary"
          onChange={() => null}
          isDisabled={true}
          isChecked={true}
          label={() => (
            <>
              <strong>{t('necessary.cookies')}</strong> - {t('necessary.cookies.description')}
            </>
          )}
        />
      </p>
      <p className="cookie-selection">
        <Checkbox
          id="functionality"
          name="functionality"
          onChange={() =>
            setCookiesConsent({ ...cookiesConsent, functionalityCookies: !cookiesConsent.functionalityCookies })
          }
          isChecked={cookiesConsent.functionalityCookies}
          label={() => (
            <>
              <strong>{t('functionality.cookies')}</strong> - {t('functionality.cookies.description')}
            </>
          )}
        />
      </p>
      <p className="cookie-selection">
        <Checkbox
          id="performance"
          name="performance"
          onChange={() =>
            setCookiesConsent({ ...cookiesConsent, performanceCookies: !cookiesConsent.performanceCookies })
          }
          isChecked={cookiesConsent.performanceCookies}
          label={() => (
            <>
              <strong>{t('performance.cookies')}</strong> - {t('performance.cookies.description')}
            </>
          )}
        />
      </p>
      <p className="cookie-selection">
        <Checkbox
          id="marketing"
          name="marketing"
          onChange={() => setCookiesConsent({ ...cookiesConsent, marketingCookies: !cookiesConsent.marketingCookies })}
          isChecked={cookiesConsent.marketingCookies}
          label={() => (
            <>
              <strong>{t('marketing.cookies')}</strong> - {t('marketing.cookies.description')}
              <p>
                <a href="https://policies.google.com/technologies/cookies/embedded?#types-of-cookies">
                  Youtube cookie policy
                </a>{' '}
                <a href="https://vimeo.com/cookie_policy">Vimeo cookie policy</a>
              </p>
            </>
          )}
        />
      </p>

      <div className="buttons">
        <button
          className="btn btn-primary btn-large"
          onClick={() => {
            onSubmit({
              cookiesConsentCollected: true,
              functionalityCookies: true,
              performanceCookies: true,
              marketingCookies: true,
            })
          }}
          data-testid="cookie-consent-all-submit-btn"
        >
          {t('accept.all.cookies')}
        </button>
        <button
          className="btn btn-secondary btn-large"
          onClick={() => {
            if (!cookiesConsent.marketingCookies || !cookiesConsent.functionalityCookies) {
              setShowSubModal(true)
            } else {
              onSubmit(cookiesConsent)
            }
          }}
          data-testid="cookie-consent-selected-submit-btn"
        >
          {t('accept.selected.cookies')}
        </button>
      </div>
    </div>
  )

  return displayCookieConsentModal ? (
    showSubModal ? (
      <>
        {' '}
        <Modal
          Component={
            <CookiesConsentConfirmationModalContent
              onSubmit={() => onSubmit(cookiesConsent)}
              setShowSubModal={setShowSubModal}
              cookiesConsent={cookiesConsent}
            />
          }
        />
      </>
    ) : (
      modal
    )
  ) : null
}

export default withI18n(CookieConsentModalContent, 'CookieConsentModalContent')
