import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getLocalLang, init } from '.'
import { translateFn, State } from '../../../types'

export interface WithI18nProps {
  t: translateFn
}

export default function withI18n<T extends WithI18nProps>(
  WrappedComponent: React.ComponentType<T>,
  i18nKeyArg?: string
) {
  return (hocProps: Omit<T, keyof WithI18nProps>) => {
    const getWrappedComponentName = (displayName: string) => {
      if (displayName == null) {
        throw new Error('WrappedComponents displayName is missing.')
      }
      var args: string[] = /\(\s*([^)]+?)\s*\)/.exec(displayName)
      if (args == null) {
        return displayName
      }
      if (args[1]) {
        args = args[1].split(/\s*,\s*/)
      }
      return args[0]
    }

    const language = getLocalLang()
    const [i18nKey, setI18nKey] = useState(i18nKeyArg || getWrappedComponentName(WrappedComponent.displayName))
    const t = init(language, i18nKey)
    const status = useSelector((state: State) => state.common.language.status)

    return <WrappedComponent t={t} {...(hocProps as T)} />
  }
}
