import { axiosApiInstance } from '../common/axios'
import { Language } from '../../types'

const USER_PATH = '/api/user'


const putLanguage = async (language: Language) => {
    return await axiosApiInstance.put(
        USER_PATH + '/language/' + language,
        {})

}

const getLanguage = async () => {
    return await axiosApiInstance.get(USER_PATH + '/language')
}

export default {
    putLanguage,
    getLanguage
}