import { axiosApiInstance } from '../common/axios'

const VIEW_CV_PUBLIC_PATH = '/api/cv/view/public/'

const getCvByUsername = async (username: string) => {
    return await axiosApiInstance.get(
        VIEW_CV_PUBLIC_PATH + username
    )
}


export default {
    getCvByUsername,
}