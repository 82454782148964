import React, { ReactElement } from 'react'
import withI18n, { WithI18nProps } from '../common/i18n/withI18n'
import { CookiesConsent } from './CookiesConsentModalContent'

export interface CookiesConsentConfirmationModalContentProps extends WithI18nProps {
  onSubmit: () => void
  setShowSubModal: (showSubModal: boolean) => void
  cookiesConsent: CookiesConsent
}

function CookiesConsentConfirmationModalContent({
  t,
  onSubmit,
  setShowSubModal,
  cookiesConsent,
}: CookiesConsentConfirmationModalContentProps): ReactElement {
  return (
    <div className="cookie-consent-modal-content">
      <h2>{t('you.have.chosen')}</h2>
      <p>{t('through.declining')}</p>
      <ul>
        {!cookiesConsent.functionalityCookies && <li>{t('functionality.cookies')}</li>}
        {!cookiesConsent.marketingCookies && <li>{t('marketing.cookies')}</li>}
      </ul>
      <p>{t('our.advice')}</p>
      <div className="buttons">
        <button
          className="btn-primary btn-large"
          onClick={() => {
            setShowSubModal(false)
          }}
          data-testid="cookie-consent-confirmation-go-back-submit-btn"
        >
          {t('change')}
        </button>
        <button
          className="btn-secondary btn-large"
          onClick={() => {
            setShowSubModal(false)
            onSubmit()
          }}
          data-testid="cookie-consent-confirmation-submit-btn"
        >
          {t('accept')}
        </button>
      </div>
    </div>
  )
}

export default withI18n(CookiesConsentConfirmationModalContent, 'CookiesConsentConfirmationModalContent')
