import { configureStore, combineReducers, ThunkAction, Action, AnyAction, PayloadAction } from '@reduxjs/toolkit'
import publicCvSlice from '../features/public-cv/publicCvSlice'
import cvSlice from '../features/cv/cvSlice'
import authSlice from '../features/auth/authSlice'
import commonSlice from '../features/common/commonSlice'
import adminSlice from '../features/admin/adminSlice'
import userSlice from '../features/user/userSlice'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { State } from '../types'

const appReducer = combineReducers({
  publicCv: publicCvSlice,
  cv: cvSlice,
  auth: authSlice,
  common: commonSlice,
  admin: adminSlice,
  user: userSlice,
})

const rootReducer = (state: State, action: PayloadAction<null>) => {
  if (action.type === 'auth/logout') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

const middleware = [thunk]

const store = configureStore({
  reducer: rootReducer,
  middleware,
})

export default store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>
