import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { isCookiesConsentCollected } from './commonSlice'

export interface ModalProps {
  Component: ReactElement
}

export default function Modal({ Component }: ModalProps): ReactElement {
  const cookiesConsentCollected = useSelector(isCookiesConsentCollected)

  return !cookiesConsentCollected ? (
    <div className="modal">
      <div className="dark-background ">
        <div className="modal-area centered">{Component}</div>
      </div>
    </div>
  ) : null
}
