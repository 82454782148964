import en from './languages/en_US'
import sv from './languages/sv_SE'

const languages = {
  'en-US': en(),
  'sv-SE': sv(),
}
export const defaultLanguage = 'en-US'

export const getBrowserLang = () => {
  const lang = window.navigator.language || window.navigator.userLanguage
  if (lang == 'sv') {
    return 'sv-SE'
  }
  if (lang == 'en') {
    return 'en-US'
  }
  return lang
}

export const getLocalLang = () => {
  const storedLanguage = localStorage.getItem('acv-language')
  return storedLanguage
}

export const getSelectedLanguage = (language) => {
  if (getLocalLang()) {
    return getLocalLang()
  }
  const browserLang = getBrowserLang()
  if (language == null && languages[browserLang]) {
    return browserLang
  }

  return language || defaultLanguage
}

export const init = (language, component) => (key) => {
  const selectedLanguage = getSelectedLanguage(language)

  if (languages[selectedLanguage] == undefined) {
    return 'MISSING LANG'
  }
  if (languages[selectedLanguage][component] == undefined) {
    return `MISSING TRANSLATION ${language}.${component}.${key}`
  }

  return languages[selectedLanguage][component][key]
}
