import axios from 'axios'
const axiosApiInstance = axios.create();

(function () {
    axiosApiInstance.defaults.headers.post['Content-Type'] = 'application/json'
    axiosApiInstance.defaults.headers.put['Content-Type'] = 'application/json'
    const token = localStorage.getItem('acv-token')
    if (token) {
        axiosApiInstance.defaults.headers.common['Authorization'] = token
    } else {
        axiosApiInstance.defaults.headers.common['Authorization'] = null
    }
})()


function setUpAxios() {
    axiosApiInstance.defaults.headers.post['Content-Type'] = 'application/json'
    axiosApiInstance.defaults.headers.put['Content-Type'] = 'application/json'
    const token = localStorage.getItem('acv-token')
    if (token) {
        axiosApiInstance.defaults.headers.common['Authorization'] = token
    } else {
        axiosApiInstance.defaults.headers.common['Authorization'] = null
    }
}

export {
    axiosApiInstance,
    setUpAxios
}

