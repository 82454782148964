import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import userApi from './commonApi'
import { CommonSlice, State, Language, ToastType } from '../../types'
import defaultState from '../../store/defaultState'
import commonApi from './commonApi'
import { v4 as uuidv4 } from 'uuid';
import { getLocalLang, getSelectedLanguage } from './i18n'

export const fetchLanguage = createAsyncThunk('common/fetchLanguage', async () => {
  try {
    const storedLanguage = getLocalLang()
    if (storedLanguage) {
      console.log('fetchLanguage cached')
      return storedLanguage
    } else {
      console.log('fetchLanguage backend')
      const response = await commonApi.getLanguage()
      return response.data.language
    }
  } catch (e) {
    console.log('Error: common/fetchLanguage', e)
  }
})

export const saveLanguage = createAsyncThunk('common/saveLanguage', async (language: Language) => {
  try {
    const storedLanguage = getLocalLang()
    if (storedLanguage != language) {
      localStorage.setItem('acv-language', language)
    }

    if (localStorage.getItem('acv-token')) {
      const response = await userApi.putLanguage(language)
      return response.data
    } else {
      return language
    }
  } catch (e) {
    console.log('Error: common/saveLanguage', e)
  }
})

export const pushLanguageToRemote = createAsyncThunk('common/pushLanguageToRemote', async () => {
  try {
    const language = getSelectedLanguage(null)
    const storedLanguage = localStorage.getItem('acv-language')
    if (!storedLanguage) {
      localStorage.setItem('acv-language', language)
    }
    const response = await userApi.putLanguage(language)
  } catch (e) {
    console.log('Error: common/pushLanguageToRemote', e)
  }
})

function removeToastItem<T>(array: Array<T>, itemId: string): Array<T> {
  const index = array.map((item) => itemId).indexOf(itemId)
  if (index > -1) {
    array.splice(index, 1)
  }
  return array
}

const commonSlice = createSlice({
  name: 'common',
  initialState: defaultState.common,
  reducers: {
    addErrorToast: (common: CommonSlice, action: PayloadAction<string>) => {
      common.toasts.push({
        id: uuidv4(),
        type: ToastType.ERROR,
        message: action.payload,
        autoDismiss: true,
      })
    },
    addSuccessToast: (common: CommonSlice, action: PayloadAction<string>) => {
      common.toasts.push({
        id: uuidv4(),
        type: ToastType.SUCCESS,
        message: action.payload,
        autoDismiss: true,
      })
    },
    removeToast: (common: CommonSlice, action: any) => {
      common.toasts = removeToastItem(common.toasts, action.payload)
    },
    cookieConsentCollected: (common: CommonSlice, action: PayloadAction<boolean>) => {
      common.cookieConsentCollected = action.payload
    },
    changeCookiesConsent: (common: CommonSlice, action: PayloadAction<boolean>) => {
      common.changeCookiesConsent = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLanguage.pending, (common: CommonSlice) => {
        common.language.status = 'loading'
      })
      .addCase(fetchLanguage.fulfilled, (common: CommonSlice, action: PayloadAction<Language>) => {
        common.language.status = 'idle'
        common.language.value = action.payload
      })
      .addCase(fetchLanguage.rejected, (common: CommonSlice) => {
        common.language.status = 'failed'
        common.language.value = null
      })

      .addCase(saveLanguage.pending, (common: CommonSlice) => {
        common.language.status = 'loading'
      })
      .addCase(saveLanguage.fulfilled, (common: CommonSlice, action: PayloadAction<Language>) => {
        common.language.status = 'idle'
        common.language.value = action.payload
      })
      .addCase(saveLanguage.rejected, (common: CommonSlice) => {
        common.language.status = 'failed'
        common.language.value = null
      })
  },
})

export const { addErrorToast, addSuccessToast, removeToast, cookieConsentCollected, changeCookiesConsent } = commonSlice.actions;

export default commonSlice.reducer

export const selectLanguage = (state: State) => state.common.language.value
export const isCookiesConsentCollected = (state: State) => state.common.cookieConsentCollected


