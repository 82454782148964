import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Spinner from 'react-spinkit'

const LandingPage = lazy(() => import('../features/landing-page/LandingPage'))
const AdminPage = lazy(() => import('../features/admin/AdminPage'))
const AccountPage = lazy(() => import('../features/account/AccountPage'))
const ControlPanelPage = lazy(() => import('../components/controlpanel/ControlPanelPage'))
const ShowCvPage = lazy(() => import('../components/cv/ShowCvPage'))
const LoginPage = lazy(() => import('../features/auth/LoginPage'))
const SignUpPage = lazy(() => import('../features/user/SignUpPage'))
const VerifySignUpPage = lazy(() => import('../features/user/VerifySignUpPage'))
const PasswordResetPage = lazy(() => import('../features/auth/PasswordResetPage'))
const RequestPasswordResetPage = lazy(() => import('../features/auth/RequestPasswordResetPage'))
const VerifyDeletePage = lazy(() => import('../features/admin/VerifyDeletePage'))

const AppRouter = () => (
  <BrowserRouter>
    <Suspense fallback={<Spinner name="ball-clip-rotate" fadeIn="quarter" color="#bcf7b5" />}>
      <Switch>
        <Route path="/" component={LandingPage} exact={true} />
        <Route path="/login" component={LoginPage} />
        <Route path="/signup/:cvIdentifier" component={SignUpPage} />
        <Route path="/verify-signup/:activationId" component={VerifySignUpPage} />
        <Route path="/password-reset/:username/:passwordResetId" component={PasswordResetPage} />
        <Route path="/request-password-reset" component={RequestPasswordResetPage} />
        <Route path="/controlpanel" component={ControlPanelPage} />
        <Route path="/admin" component={AdminPage} exact={true} />
        <Route path="/admin/verify-delete/:username" component={VerifyDeletePage} />
        <Route path="/account" component={AccountPage} />
        <Route path="/:id" component={ShowCvPage} />
      </Switch>
    </Suspense>
  </BrowserRouter>
)
export default AppRouter
