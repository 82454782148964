import React, { ReactElement } from 'react'

export interface CheckboxProps {
  id: string
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  label(): ReactElement
  isChecked?: boolean
  isDisabled?: boolean
}

export default function Checkbox({
  id,
  name,
  onChange,
  label,
  isChecked,
  isDisabled = false,
}: CheckboxProps): ReactElement {
  return (
    <div className="checkbox">
      <label className="container">
        <input type="checkbox" id={id} name={name} onChange={onChange} checked={isChecked} disabled={isDisabled} />
        <span className="checkmark"></span>
        {label && label()}
      </label>
    </div>
  )
}
