import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import authApi from './authApi'
import { AuthSlice, State } from '../../types'
import defaultState from '../../store/defaultState'
import { addErrorToast } from '../common/commonSlice'
import { setUpAxios } from '../common/axios'

export const login = createAsyncThunk('auth/login', async ({ username, password }: { username: string, password: string }, { dispatch }) => {
  try {
    const response = await authApi.postLogin(username, password)
    return response.headers?.authorization
  } catch (e) {
    console.log('Error: auth/login', e)
    if (e?.request?.status == 403) {
      dispatch(addErrorToast(`Login failed: Wrong email or password`))
    } else {
      dispatch(addErrorToast(`Login failed: ${e.message}`))
    }
    throw e
  }
})

export const passwordReset = createAsyncThunk('auth/passwordReset', async ({ passwordResetId, username, password }: { passwordResetId: string, username: string, password: string }, { dispatch }) => {
  try {
    await authApi.postPasswordReset(passwordResetId, username, password)
  } catch (e) {
    console.log('Error: auth/passwordReset', e)
    if (e.response.data.error) {
      dispatch(addErrorToast(`Password reset failed: ${e.response.data.error}`))
    } else {
      dispatch(addErrorToast(`Password reset failed: ${e.message}`))
    }
    throw e
  }
})

export const requestPasswordReset = createAsyncThunk('auth/requestPasswordReset', async (username: string, { dispatch }) => {
  try {
    await authApi.getPasswordReset(username)
  } catch (e) {
    console.log('Error: auth/requestPasswordReset', e)
    if (e.response.data.error) {
      dispatch(addErrorToast(`Password reset failed: ${e.response.data.error}`))
    } else {
      dispatch(addErrorToast(`Password reset failed: ${e.message}`))
    }
    throw e
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState.auth,
  reducers: {
    logout: (auth: AuthSlice) => {
      const token = localStorage.getItem('acv-token')
      if (token !== 'undefined') {
        try {
          localStorage.removeItem('acv-token')
          auth.token = null
          setUpAxios()
        } catch (e) {
          console.log('Unable to forget local token', e)
        }
      }
    },
    checkForLocalToken: (auth: AuthSlice) => {
      console.log('checkForLocalToken action', { auth })
      const token = localStorage.getItem('acv-token')
      if (token !== 'undefined' && token != null) {
        try {
          const payload = JSON.parse(atob(token.split('.')[1]))
          const username = payload.sub
          const expires = new Date(payload.exp * 1000)
          if (expires > new Date()) {
            auth.token = token
            auth.username = username
            setUpAxios()
          } else {
            console.log('Token is expired')
          }
        } catch (e) {
          console.log('Unable to decode local token', e)
        }
      } else {
        console.log('No local storage token found')
      }
    },
    resetRequestPasswordReset: (auth: AuthSlice) => {
      auth.requestPasswordReset = undefined
    },
    resetResetPassword: (auth: AuthSlice) => {
      auth.passwordReset = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(passwordReset.fulfilled, (auth: AuthSlice) => {
        auth.passwordReset = {
          success: true,
          requested: true,
          error: false
        }
      })
      .addCase(passwordReset.rejected, (auth: AuthSlice) => {
        auth.passwordReset = {
          success: false,
          requested: true,
          error: true
        }
      })
      .addCase(requestPasswordReset.fulfilled, (auth: AuthSlice) => {
        auth.requestPasswordReset = {
          success: true,
          requested: true,
          error: false
        }
      })
      .addCase(requestPasswordReset.rejected, (auth: AuthSlice) => {
        auth.requestPasswordReset = {
          success: false,
          requested: true,
          error: true
        }
      })
      .addCase(login.fulfilled, (auth: AuthSlice, action: PayloadAction<string>) => {
        const token = action.payload
        const payload = JSON.parse(atob(token.split('.')[1]))
        const username = payload.sub
        const expires = new Date(payload.exp * 1000)
        if (expires > new Date()) {
          auth.token = token
          auth.username = username
          localStorage.setItem('acv-token', token)
          setUpAxios()
        } else {
          console.log('Token is expired')
          auth.token = null
          auth.username = null
        }
      })
      .addCase(login.rejected, (auth: AuthSlice) => {
        auth.username = null
        auth.token = null
      })
  },
})

export const { logout, checkForLocalToken, resetRequestPasswordReset, resetResetPassword } = authSlice.actions;

export default authSlice.reducer

export const selectToken = (state: State) => state.auth.token
export const selectIsAuthenticated = (state: State) => !!state.auth.token



