export interface Role {
  authority: string
}

export interface UserSlice {
  status: Status
  value: User
  signUp?: {
    requested: boolean | null,
    success: boolean | null,
    error: boolean | null
  },
  verifySignUp?: {
    success: boolean | null,
    error: boolean | null
  }
}

export type Language = 'sv-SE' | 'en-US'

export interface User {
  id: number
  cvIdentifier: string
  username: string
  created: number
  language: Language
  lastPaymentStatus?: LastPaymentStatus
  roles: Role[]
  stripeCustomerId?: string
  plan: Plan
  cookiesConsentCollected: boolean
  functionalityCookies: boolean
  performanceCookies: boolean
  marketingCookies: boolean
}

export enum LastPaymentStatus {
  SUCCEEDED = 'SUCCEEDED',
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
}

export enum Plan {
  PAID = 'PAID',
  FREE = 'FREE',
  FOREVER_FREE = 'FOREVER_FREE',
  CANCELLED = 'CANCELLED',
  INACTION = 'INACTION',
  TEST = 'TEST',
  ENDED = 'ENDED'
}

export interface AuthSlice {
  username: string | undefined
  token: string | undefined
  activationId: string | undefined
  passwordReset?: {
    requested: boolean | null,
    success: boolean | null,
    error: boolean | null,
  }
  requestPasswordReset?: {
    requested: boolean | null,
    success: boolean | null,
    error: boolean | null,
  }
}

export enum ToastType {
  ERROR = 'error',
  SUCCESS = 'succes',
}

export interface ToastMessage extends CreateToastMessage {
  id: string
}
export interface CreateToastMessage {
  type: ToastType
  message: string
  autoDismiss: boolean
}
export type Status = 'idle' | 'loading' | 'failed' // Todo

export interface AdminSlice {
  userCount: {
    status: Status
    value: number
  }
  todaysEmailCount: {
    status: Status
    value: number
  }
  todaysUserCount: {
    status: Status
    value: number
  }
  paidUserCount: {
    status: Status
    value: number
  }
  userAquiringStats: {
    status: Status
    value: UserAquiringStatsListItem[]
  }
}

export interface CommonSlice {
  language: {
    status: Status
    value: Language
  }
  toasts: ToastMessage[]
  cookieConsentCollected: boolean
  changeCookiesConsent: boolean
}

export interface State {
  publicCv: PublicCvSlice | null
  cv: CvSlice | null
  admin: AdminSlice | null
  user: UserSlice | null
  auth: AuthSlice
  common: CommonSlice
}

export type translateFn = {
  description: string
  (key: string): string
}

export enum ExperienceType {
  WORK = 'WORK',
  TITLE = 'TITLE',
}
export interface Experience {
  '@type': ExperienceType
  id: number
  cvId: number
  type: string
  index: number
  title: string
}

export interface Photo {
  id: number
  cvId: number
  url: string
  hash: string
  primaryPhoto: boolean
  index: number
  height: number
  width: number
  src: string
}

export interface Video {
  id: number
  cvId: number
  link: string
  cover: string
  index: number
}

export interface CvSlice {
  status: Status
  value: Cv
  code: number
}


export interface PublicCvSlice extends CvSlice {
}

export interface Cv {
  id: number
  cvIdentifier: string
  photos: Photo[]
  videos: Video[]
  experiences: Experience[]
  firstName: string
  lastName: string
  city: string
  country: string
  phone: string
  dateOfBirth: string
  introduction: string
  hideEmail: boolean
  user: User
  age: number
  email: string
  visible: boolean
  primaryPhoto: Photo
}

export interface UserListItem {
  email: string
  firstName: string
  lastName: string
  cvIdentifier: string
  created: string
  cv: boolean
  photos: boolean
  experience: boolean
  videos: boolean
}

export interface UserAquiringStatsListItem {
  yearMonth: string
  usersAcquired: number
}
