export default () => ({
  LandingPageAboveFold: {
    features: 'WHY ACTORCV?',
    example: 'EXAMPLE',
    'try.free': 'TRY FREE',
    'create.your.own': 'Create your own',
    'actors.website': 'actors website',
    'set.up.your': 'Set up your website in 15 minutes.',
    'designed.in.collaboration': 'Designed in collaboration with professional casting agents.',
  },
  StartSignUpForm: {
    'please.provide.a.name': 'Please provide the name for your page.',
    'please.choose.a.page': 'Please choose a page name that is available.',
    'its.available': "It's available!",
    'already.taken.sorry': 'Already taken, sorry!',
    'sign.up': 'TRY FREE',
    'your.name': 'Enter your name',
    'do.you.already': 'Have an account already?',
  },
  Benefits: {
    why: 'Why ACTORCV?',
    'simple.as.can': 'Simple as can be',
    'setting.up.your.page':
      'Setting up your page is super simple. Upload some photos, link to your showreels and wire up your resume.',
    'casters.love.it': 'Casters love it',
    'we.have.developed.the.prooduct':
      "We've developed the product based on first hand collaboration with casting directors.",
    'share.with.ease': 'Share with ease',
    'create.your.own': 'Create your own website and simply send them your link.',
    'not.technical': 'NOT TECHNICAL',
    'dont.get.caught.up': "Don't get caught up in the technicalities of creating a website. We got you covered.",
    'no.decisions': 'NO DECISIONS',
    'dont.spend.time.thinking':
      "Don't spend time thinking about how it should look. We have already done this for you.",
    'not.a.handful': 'NOT A HANDFUL',
    'sharing.and.managing.heavy':
      'Sharing and managing heavy files in different formats is a pain. Let us deal with that.',
  },
  Story: {
    'great.for.everyone': 'About us',
    'actorcv.was.created.by.actors':
      'ActorCV is created by actors for actors. We realised that a lot of people still send PDFs to casting agents. So we created ActorCV. A professional actor needs a website to showcase oneself.',
    'we.spent.way.too.long': '',
    'we.would.love.to.hear':
      'We’d love to hear your feedback, good or bad! Whatever it is we are all ears. We are constantly looking to improve our service.',
  },
  ControlPanelPage: {
    'edit.your.page': 'EDIT YOUR PAGE',
    'there.is.a.problem':
      'There is a problem with your account and your page is not visible. Only you can see your page right now.',
    'go.to': 'Go to',
    'to.resolve': 'to resolve the issue.',
    account: 'ACCOUNT',
  },
  EditCvForm: {
    'you.can.not': 'You can not write an introduction of more than 255 characters',
    'personal.information': 'Personal information',
    'first.name': 'First name',
    'last.name': 'First name',
    city: 'City',
    country: 'Country',
    email: 'Email',
    phone: 'Phone',
    'date.of.birth': 'Date of birth',
    introduction: 'Introduction',
    edit: 'EDIT',
    'hide.my.email': 'Hide my email on my public page.',
    save: 'SAVE',
  },
  PhotoManagement: {
    photos: 'PHOTOS',
    'add.photo': 'Add photo',
    delete: 'DELETE',
    reorder: 'REORDER',
    'select.hero': 'SELECT',
    done: 'DONE',
  },
  ListPhotoForm: {
    rearrange: 'REARRANGE',
    photos: 'PHOTOS',
    videos: 'VIDEOS',
    save: 'SAVE',
  },
  AddPhotoForm: {
    'select.file': 'SELECT FILE',
  },
  VideoManagement: {
    videos: 'VIDEOS',
    'videos.are.playable': '*videos are playable in CV-view',
    delete: 'DELETE',
    reorder: 'REORDER',
    done: 'DONE',
  },
  AddVideoForm: {
    'only.links.from': 'Only links from youtube or vimeo are accepted.',
    'add.video': 'Add video',
    link: 'LINK',
    add: 'ADD',
  },
  ExperienceManagement: {
    title: 'RESUME',
    title: 'RESUME',
    delete: 'DELETE',
    reorder: 'REORDER',
    edit: 'EDIT',
    done: 'DONE',
  },
  AddExperienceForm: {
    'you.need.to': 'You need to enter something in at least one field to be able to save.',
    'add.resume': 'Add resume',
    experience: 'Experience',
    category: 'Category',
    'category.title': 'Category title',
    year: 'Year',
    movies: 'Movies',
    'project.example': 'The Great Film',
    project: 'PROJECT',
    'character.example': 'Batman or Lead/Supporting',
    character: 'ROLE',
    'director.example': 'Directed by Laila Smith',
    director: 'DIRECTOR',
    'production.company.example': 'Great Film Productions',
    'production.company': 'PRODUCTION COMPANY',
    add: 'ADD',
  },
  EditExperienceForm: {
    title: 'Title',
    year: 'Year',
    project: 'Project',
    role: 'Role',
    director: 'Director',
    'production.company': 'Production company',
    save: 'SAVE',
  },
  AccountPage: {
    account: 'ACCOUNT',
    'acv.profile': 'Acv page',
    username: 'Username',
    'user.created': 'User created',
    subscription: 'Subscription',
    '30d.free.ends.in': '30 days free. Ends in',
    days: 'days',
    'subscription.required.since': 'Subscription required since',
    'your.subscription.is.payed': 'Your subscription is payed and your page is visible.',
    'your.profile.is.temporarily.suspended':
      'Your profile is temporarily suspended because we are missing your payment.',
    'your.free.plan.is.up': 'You need to start a subscription. Your page is not visible.',
    'subscription.activated': 'Subscription activated. Your page is visible.',
    'subscription.sign.up.canceled': 'Subscription sign up canceled. Your page is not visible.',
    'log.out': 'LOG OUT',
    'your.account.is.free.until': 'Your free trial ends on',
    'your.profile.is.visible': 'Your page is visible.',
    'visit.cutomer.portal':
      'To manage your subscription, see receipts and payments etc. please visit the Stripe Customer Portal.',
  },
  CheckoutForm: {
    'select.a.subscription.to.keep': 'Select a subscription to keep your page visible after the trial period ends.',
    'select.a.subscription.to.enable': 'Select a subscription to make your page visible again.',
    'monthly.sub': 'Monthly subscription',
    monthly: '/month',
    '3usd': '3',
    '.90usd': '99',
    'yearly.sub': 'Yearly subscription',
    yearly: '/year',
    '39usd': '37',
    '.00usd': '00',
    'monthly.details': 'Billed monthly. Cancel anytime.',
    'yearly.details': 'Billed yearly. Cancel anytime.',
    'yearly.discount': '20% OFF!',
    subscribe: 'SUBSCRIBE',
  },
  CustomerPortalForm: {
    change: 'Stripe Customer Portal',
  },
  LoginPage: {
    title: 'log in',
    'forgot.password': 'Forgot your password?',
  },
  LoginForm: {
    'please.provide': 'Please provide a username and password.',
    email: 'Email',
    password: 'Password',
    'log.in': 'LOG IN',
  },
  SignUpPage: {
    'your.email.link': 'Here you go, here is the link to your email!',
    'sign.up.success': 'SIGN UP SUCCESSFUL',
    'sign.up.error': 'SIGN UP FAILED',
    'something.went.wrong':
      'Something went wrong trying to register your new account. Please contact support or try again later.',
    'activate.your.account':
      'Activate your account by clicking on the link in the e-mail you’ve been sent. If you can’t find it, check your junk mail!',
    'sign.up': 'SIGN UP',
    'by.signing.up': 'By signing up you agree to our',
    'terms.and.conditions': 'Terms and Conditions',
    and: 'and our',
    'privacy.policy': 'Privacy Policy',
  },
  SignUpForm: {
    email: 'Email',
    password: 'Password',
    'repeat.password': 'Repeat password',
    'sign.up.free': 'SIGN UP FREE',
    'please.choose.your.password': 'Please choose your password.',
    'passwords.do.not.match': 'Passwords does not match.',
    'invalid.email': 'Invalid email address.',
  },
  RequestPasswordResetPage: {
    'reset.password': 'reset password',
    'reset.password.success': 'Request password reset success',
    'reset.password.success.info':
      'If your email is registered with one of our accounts you will receive an email with instructions how to rest your password.',
    'reset.password.error': 'Request password reset failed',
  },
  RequestPasswordResetForm: {
    email: 'Email',
    submit: 'SUBMIT',
    'please.enter': 'Please enter your email to reset your password.',
    'invalid.email': 'Invalid email address.',
  },
  VerifySignUpPage: {
    title: 'VERIFY ACCOUNT',
    success: 'Signup verification succeeded! You will be redirected to the login page.',
    error: 'An error occured during sign up verification.',
  },
  Header: {
    admin: 'ADMIN',
    cv: 'CV',
    account: 'ACCOUNT',
    'log.in': 'LOG IN',
    'edit.cv': 'EDIT CV',
    'view.cv': 'VIEW CV',
  },
  CvHeader: {
    'edit.cv': 'EDIT CV',
    'view.cv': 'VIEW CV',
    photos: 'PHOTOS',
    videos: 'VIDEOS',
    resume: 'RESUME',
  },
  ShowCvPersonalInfo: {
    'years.old': 'years old',
  },
  ShowCvPhotos: {
    photos: 'PHOTOS',
  },
  ShowCvVideos: {
    videos: 'VIDEOS',
  },
  ShowCvExperience: {
    resume: 'RESUME',
  },
  CvError: {
    'we.are.sorry': 'We are sorry',
    'we.know.this.user':
      'We know this user, but the page is currently closed. Let them know you got this message so they can fix it!',
    'but.we.dont.know': "But we don't know the user you are looking for.",
    'there.was.an.unexpected': 'There was an unexpected error, please report this to support if the problem persists.',
  },
  AdminPage: {
    admin: 'ADMIN',
    users: 'USERS',
    emails: 'EMAILS',
    'stripe.events': 'STRIPE',
  },
  Count: {
    users: 'USERS (TOTAL)',
    'users.today': 'USERS (TODAY)',
    'users.paid': 'PAYING USERS',
    emails: 'EMAILS (TODAY)',
  },
  RunScheduled: {
    run: 'RUN PROCESS',
  },
  DataTablePaginated: {
    reset: 'RESET',
    name: 'Name',
    email: 'Email',
    cv: 'Cv',
    photos: 'Photos',
    resume: 'Resume',
    videos: 'Videos',
    created: 'Created',
    delete: 'Delete',
    cvStatus: 'Cv status',
    stripeCustomerId: 'Stripe Id',
    lastPaymentStatus: 'Payment status',
    plan: 'Plan',
    action: 'Action',

    userId: 'User Id',
    email: 'Email',
    template: 'Template',
    language: 'Language',
    created: 'Created',

    eventId: 'Event Id',
    customerId: 'Customer Id',
    processed: 'Processed',
  },
  UserStats: {
    'users.aquired': 'Users aquired',
  },
  VerifyDeletePage: {
    'verify.delete': 'Verify delete',
    'you.are.trying': 'You are trying to delete the user',
    'this.action.will': 'This action will delete the user and all its data from ACV.',
    'type.the.username': 'Type the username (email) below to proceed.',
    delete: 'Delete',
    'deleted.user': 'Deleted user',
  },
  FilterComponent: {
    email: 'Email',
    reset: 'RESET',
  },
  PasswordResetForm: {
    password: 'Password',
    'repeat.password': 'Repeat password',
    submit: 'submit',
    'please.choose.password': 'Please choose your password.',
    'passwords.does.not.match': 'Passwords does not match.',
  },
  PasswordResetPage: {
    'reset.password': 'Reset password',
    'reset.password.success': 'Reset password suceeded',
    'reset.password.error': 'Reset password failed',
  },
  EmailProviderLink: {
    'your.email.link': 'Here you go, here is the link to your email!',
  },
  CookieConsentModalContent: {
    'cookie.consent': 'Cookie Consent',
    'this.website': 'This website uses cookies to ensure you get the best possible experience.',
    'learn.more.about.cookies': 'Learn more about cookies',
    'how.cookies': 'How we use cookies',
    'we.use.cookies': 'We use cookies, and you must actively choose which cookies you accept.',
    'accept.all.cookies': 'Accept all cookies',
    'accept.selected.cookies': 'Accept selected cookies',
    'necessary.cookies': 'Necessary cookies',
    'necessary.cookies.description': 'These cookies are essential for the website to function and cannot be declined.',
    'functionality.cookies': 'Functionality cookies',
    'functionality.cookies.description':
      'These cookies are needed to remember the choices you have made in order to improve the website. Like remembering your language preference.',
    'performance.cookies': 'Performance cookies',
    'performance.cookies.description':
      'These cookies are used to collect data on how visitors use the website in order to improve it. We use Hotjar to monitor usability.',
    'marketing.cookies': 'Marketing cookies',
    'marketing.cookies.description':
      'These are mainly third-party cookies from services like YouTube and Vimeo, used to display videos on the website. Please refer to their respective cookie policies for updated information on how they are used.',
  },
  CookiesConsentConfirmationModalContent: {
    'you.have.chosen': 'You have opted out of functionality',
    'through.declining':
      'By declining cookies for functionality, you will not be able to use certain features on the website. This may affect your user experience.',
    'functionality.cookies':
      'You have opted out of Functionality cookies. This means that your language settings may not work as well.',
    'marketing.cookies':
      'You have opted out of Marketing cookies. This means that we cannot display any video content on the site.',
    'our.advice': 'Our advice is to accept all cookies to get the best experience and access to all features.',
    change: 'CHANGE MY COOKIE PREFERENCES',
    accept: 'CONTINUE WITH SELECTED COOKIES',
  },
  VideoDisabled: {
    'we.sorry': "We're sorry",
    'you.have.disabled':
      'You have disabled video for this site by not accepting marketing-cookies. To enable video content, please review your cookie consent.',
    'review.consent': 'REVIEW COOKIE CONSENT',
  },
})
