import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import cvApi from './cvApi'
import { Cv, CvSlice, Experience, Photo, State, Video } from '../../types'
import defaultState from '../../store/defaultState'


export const fetchCv = createAsyncThunk('cv/fetchCv', async () => {
  try {
    const response = await cvApi.getCv()
    return response.data
  } catch (e) {
    console.log('Error: cv/fetchCv', e)
  }
})

export const saveCv = createAsyncThunk('cv/saveCv', async (cv: Cv) => {
  try {
    console.log('SAVE CV', cv)
    const response = await cvApi.saveCv(cv)
    return response.data
  } catch (e) {
    console.log('Error: cv/saveCv', e)
  }
})
export const addPhoto = createAsyncThunk('cv/addPhoto', async ({ cvId, file }: { cvId: number, file: File | undefined }) => {
  try {
    let formData = new FormData()
    formData.append('file', file, file.name)
    const response = await cvApi.postPhoto(cvId, formData)
    return response.data
  } catch (e) {
    console.log('Error: cv/addPhoto', e)
  }
})

export const removePhoto = createAsyncThunk('cv/removePhoto', async ({ cvId, photoId }: { cvId: number, photoId: number }) => {
  try {
    const response = await cvApi.deletePhoto(cvId, photoId)
    return response.data
  } catch (e) {
    console.log('Error: cv/removePhoto', e)
  }
})

export const selectPrimaryPhoto = createAsyncThunk('cv/selectPrimaryPhoto', async ({ cvId, photoId }: { cvId: number, photoId: number }) => {
  try {
    const response = await cvApi.putSelectPrimaryPhoto(cvId, photoId)
    return response.data
  } catch (e) {
    console.log('Error: cv/selectPrimaryPhoto', e)
  }
})

export const savePhotos = createAsyncThunk('cv/savePhotos', async ({ cvId, photos }: { cvId: number, photos: Photo[] }) => {
  try {
    const response = await cvApi.putPhotos(cvId, photos)
    return response.data
  } catch (e) {
    console.log('Error: cv/savePhotos', e)
  }
})

export const removeExperience = createAsyncThunk('cv/removeExperience', async ({ cvId, experienceId }: { cvId: number, experienceId: number }) => {
  try {
    const response = await cvApi.deleteExperience(cvId, experienceId)
    return response.data
  } catch (e) {
    console.log('Error: cv/removeExperience', e)
  }
})

export const updateExperience = createAsyncThunk('cv/updateExperience', async ({ cvId, experience }: { cvId: number, experience: Experience }) => {
  try {
    const response = await cvApi.putExperience(cvId, experience)
    return response.data
  } catch (e) {
    console.log('Error: cv/updateExperience', e)
  }
})

export const saveExperiences = createAsyncThunk('cv/saveExperiences', async ({ cvId, experiences }: { cvId: number, experiences: Experience[] }) => {
  try {
    const response = await cvApi.putExperiences(cvId, experiences)
    return response.data
  } catch (e) {
    console.log('Error: cv/saveExperiences', e)
  }
})

export const addExperience = createAsyncThunk('cv/addExperience', async ({ cvId, experience }: { cvId: number, experience: Experience }) => {
  try {
    const response = await cvApi.postExperience(cvId, experience)
    return response.data
  } catch (e) {
    console.log('Error: cv/addExperience', e)
  }
})

export const removeVideo = createAsyncThunk('cv/removeVideo', async ({ cvId, videoId }: { cvId: number, videoId: number }) => {
  try {
    const response = await cvApi.deleteVideo(cvId, videoId)
    return response.data
  } catch (e) {
    console.log('Error: cv/removeVideo', e)
  }
})

export const saveVideos = createAsyncThunk('cv/saveVideos', async ({ cvId, videos }: { cvId: number, videos: Video[] }) => {
  try {
    const response = await cvApi.putVideos(cvId, videos)
    return response.data
  } catch (e) {
    console.log('Error: cv/saveVideos', e)
  }
})

export const addVideo = createAsyncThunk('cv/addVideo', async ({ cvId, video }: { cvId: number, video: Video }) => {
  try {
    const response = await cvApi.postVideo(cvId, video)
    return response.data
  } catch (e) {
    console.log('Error: cv/addVideo', e)
  }
})




const cvSlice = createSlice({
  name: 'cv',
  initialState: defaultState.cv,
  reducers: {
    //

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCv.pending, (cv: CvSlice) => {
        cv.status = 'loading'
      })
      .addCase(fetchCv.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(fetchCv.rejected, (cv: CvSlice) => {
        cv.status = 'failed'
        cv.value = null
      })
      .addCase(saveCv.pending, (cv: CvSlice) => {
        cv.status = 'loading'
      })
      .addCase(saveCv.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(saveCv.rejected, (cv: CvSlice) => {
        cv.status = 'failed'
        cv.value = null
      })
      .addCase(addPhoto.pending, (cv: CvSlice) => {
        cv.status = 'loading'
      })
      .addCase(addPhoto.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(addPhoto.rejected, (cv: CvSlice) => {
        cv.status = 'failed'
        cv.value = null
      })
      .addCase(removePhoto.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(selectPrimaryPhoto.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(savePhotos.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(removeExperience.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(updateExperience.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(saveExperiences.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(addExperience.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(removeVideo.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(saveVideos.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
      .addCase(addVideo.fulfilled, (cv: CvSlice, action: PayloadAction<Cv>) => {
        cv.status = 'idle'
        cv.value = action.payload
      })
  },
})

export const { } = cvSlice.actions;

export default cvSlice.reducer

export const selectCv = (state: State) => state.cv?.value
export const cvIsLoading = (state: State) => state.cv?.status == 'loading'


