import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import userApi from './userApi'
import { State, User, UserSlice } from '../../types'
import defaultState from '../../store/defaultState'
import { addErrorToast, cookieConsentCollected, pushLanguageToRemote, changeCookiesConsent } from '../common/commonSlice'
import { logout } from '../auth/authSlice'
import { CookiesConsent } from './CookiesConsentModalContent'
import { clearCookiesConsentFromLocalStorage, readCookiesConsentFromLocalStorage, writeCookiesConsentToLocalStorage } from './CookiesConsentUtil'

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, { dispatch }) => {
  try {
    const response = await userApi.getUser()

    /* Push local states towards backend if backend is missing them */
    const localStorageCookiesConsent = readCookiesConsentFromLocalStorage()
    if (localStorageCookiesConsent && response.data && !response.data.cookiesConsentCollected) {
      dispatch(pushCookiesConsentRemote())
    }
    if (response.data && !response.data.language) {
      dispatch(pushLanguageToRemote())
    }


    return response.data
  } catch (e) {
    console.log('Error: user/fetchUser', e)
    if (e.response.data.status == 404) {
      dispatch(logout())
    }
  }
})

export const signUp = createAsyncThunk('user/signUp', async (userData: any, { dispatch }) => {
  try {
    const response = await userApi.postSignUp(userData)
    return response.data
  } catch (e) {
    console.log('Error: user/signUp', e)
    if (e.response.data.error) {
      dispatch(addErrorToast(`Sign up failed: ${e.response.data.error}`))
    } else {
      dispatch(addErrorToast(`Sign up failed: ${e.message}`))
    }
    throw e
  }
})

export const verifySignUp = createAsyncThunk('user/verifySignUp', async (activationId: string, { dispatch }) => {
  try {
    const response = await userApi.postVerifySignUp({ activationId })
    return response.data
  } catch (e) {
    console.log('Error: user/verifySignUp', e)
    if (e.response.data.error) {
      dispatch(addErrorToast(`Account verification failed: ${e.response.data.error}`))
    } else {
      dispatch(addErrorToast(`Account verification failed: ${e.message}`))
    }
    throw e
  }
})

export const resetCookiesConsent = createAsyncThunk('user/saveCookiesConsent', async ({ isAuthenticated }: { isAuthenticated: boolean }, { dispatch }) => {
  try {
    console.log('RESETING cookiesConsent', { isAuthenticated })
    if (isAuthenticated) {
      await dispatch(saveCookiesConsentRemote({
        cookiesConsentCollected: false,
        functionalityCookies: false,
        performanceCookies: false,
        marketingCookies: false
      }))

    }
    dispatch(cookieConsentCollected(false))
    clearCookiesConsentFromLocalStorage()
  } catch (e) {
    console.log('Error: user/saveCookiesConsent', e)
  }
})

export const reviewCookiesConsent = createAsyncThunk('user/changeCookiesConsent', async ({ isAuthenticated }: { isAuthenticated: boolean }, { dispatch }) => {
  try {
    console.log('REVIEW cookiesConsent', { isAuthenticated })
    dispatch(changeCookiesConsent(true))
  } catch (e) {
    console.log('Error: user/changeCookiesConsent', e)
  }
})

export const saveCookiesConsent = createAsyncThunk('user/saveCookiesConsent', async ({ cookiesConsent, isAuthenticated }: { cookiesConsent: CookiesConsent, isAuthenticated: boolean }, { dispatch }) => {
  try {
    dispatch(cookieConsentCollected(true))
    if (isAuthenticated) {
      await dispatch(saveCookiesConsentRemote(cookiesConsent))
      await dispatch(saveCookiesConsentLocal(cookiesConsent))
    } else {
      await dispatch(saveCookiesConsentLocal(cookiesConsent))
    }
  } catch (e) {
    console.log('Error: user/saveCookiesConsent', e)
  }
})

export const pushCookiesConsentRemote = createAsyncThunk('user/pushCookiesConsentRemote', async (_, { dispatch }) => {
  try {
    const cookiesConsent: CookiesConsent = readCookiesConsentFromLocalStorage()
    const response = await userApi.putCookieConsent(cookiesConsent)
    dispatch(cookieConsentCollected(true))
    return response.data
  } catch (e) {
    console.log('Error: user/saveCookiesConsent', e)
  }
})

export const saveCookiesConsentRemote = createAsyncThunk('user/saveCookiesConsentRemote', async (cookiesConsent: CookiesConsent, { dispatch }) => {
  try {
    const response = await userApi.putCookieConsent(cookiesConsent)
    dispatch(cookieConsentCollected(true))
    return response.data
  } catch (e) {
    console.log('Error: user/saveCookiesConsent', e)
  }
})

export const saveCookiesConsentLocal = createAsyncThunk('user/saveCookiesConsentLocal', async (cookiesConsent: CookiesConsent, { dispatch }) => {
  try {
    writeCookiesConsentToLocalStorage(cookiesConsent)
    dispatch(cookieConsentCollected(true))
  } catch (e) {
    console.log('Error: user/saveCookiesConsent', e)
  }
})

const userSlice = createSlice({
  name: 'user',
  initialState: defaultState.user,
  reducers: {
    resetVerifySignUp: (user: UserSlice) => {
      user.verifySignUp = undefined
    },
    resetSignUp: (user: UserSlice) => {
      user.signUp = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifySignUp.fulfilled, (user: UserSlice) => {
        user.verifySignUp = {
          success: true,
          error: false
        }
      })
      .addCase(verifySignUp.rejected, (user: UserSlice) => {
        user.verifySignUp = {
          success: false,
          error: true
        }
      })
      .addCase(signUp.fulfilled, (user: UserSlice) => {
        user.signUp = {
          success: true,
          requested: true,
          error: false
        }
      })
      .addCase(signUp.rejected, (user: UserSlice) => {
        user.signUp = {
          requested: true,
          success: false,
          error: true
        }
      })
      .addCase(fetchUser.pending, (user: UserSlice) => {
        user.status = 'loading'
      })
      .addCase(fetchUser.fulfilled, (user: UserSlice, action: PayloadAction<User>) => {
        user.status = 'idle'
        user.value = action.payload
      })
      .addCase(fetchUser.rejected, (user: UserSlice) => {
        user.status = 'failed'
        user.value = null
      })
      .addCase(saveCookiesConsentRemote.pending, (user: UserSlice) => {
        user.status = 'loading'
      })
      .addCase(saveCookiesConsentRemote.fulfilled, (user: UserSlice, action: PayloadAction<User>) => {
        user.status = 'idle'
        user.value = action.payload
      })
      .addCase(saveCookiesConsentRemote.rejected, (user: UserSlice) => {
        user.status = 'failed'
        user.value = null
      })
  },
})

export const { resetSignUp, resetVerifySignUp } = userSlice.actions;

export default userSlice.reducer

export const selectUser = (state: State) => state.user?.value
export const selectUserCookieConsent = (state: State) => ({
  cookiesConsentCollected: state.user?.value?.cookiesConsentCollected,
  functionalityCookies: state.user?.value?.functionalityCookies,
  performanceCookies: state.user?.value?.performanceCookies,
  marketingCookies: state.user?.value?.marketingCookies,
})


