import { User } from '../../types'
import { axiosApiInstance } from '../common/axios'
import { CookiesConsent } from './CookiesConsentModalContent'

const USER_PATH = '/api/user'
const SIGN_UP_PATH = '/api/user/sign-up'
const VERIFY_SIGN_UP_PATH = '/api/user/verify-sign-up'


const getUser = async () => {
    return await axiosApiInstance.get<User>(USER_PATH)
}

export const postSignUp = async (userData: any) => {
    return await axiosApiInstance.post(
        SIGN_UP_PATH,
        JSON.stringify(userData))
}

export const postVerifySignUp = async (activationId: object) => {
    return await axiosApiInstance.post(
        VERIFY_SIGN_UP_PATH,
        JSON.stringify(activationId))
}

const putCookieConsent = async (cookiesConsent: CookiesConsent) => {
    return await axiosApiInstance.put(
        USER_PATH + '/cookies-consent',
        cookiesConsent)

}


export default {
    getUser,
    postSignUp,
    postVerifySignUp,
    putCookieConsent,
}