import { State } from '../types'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppThunkDispatch, AppDispatch } from '../store/createStore'

// type UseAppState = readonly [State, AppDispatch]

export const useAppDispatch = () => useDispatch<AppThunkDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// export const useAppState = (): UseAppState => [useAppSelector(), useAppDispatch()]
