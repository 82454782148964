import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import adminApi from './adminApi'
import { State, UserAquiringStatsListItem, UserListItem } from '../../types'
import defaultState from '../../store/defaultState'

export const fetchAdminUserCount = createAsyncThunk('admin/fetchAdminUserCount', async () => {
  try {
    const response = await adminApi.getAdminUserCount()
    return response.data
  } catch (e) {
    console.log('Error: admin/fetchAdminUserCount', e)
  }
})

export const fetchAdminTodaysEmailCount = createAsyncThunk('admin/fetchTodaysEmailCount', async () => {
  try {
    const response = await adminApi.getTodaysEmailCount()
    return response.data
  } catch (e) {
    console.log('Error: admin/fetchTodaysEmailCount', e)
  }
})

export const fetchAdminTodaysUserCount = createAsyncThunk('admin/fetchAdminTodaysUserCount', async () => {
  try {
    const response = await adminApi.getTodaysUserCount()
    return response.data
  } catch (e) {
    console.log('Error: admin/fetchAdminTodaysUserCount', e)
  }
})

export const fetchAdminUsersByPlanCount = createAsyncThunk('admin/fetchAdminUsersByPlanCount', async ({ plan }: { plan: string }) => {
  try {
    const response = await adminApi.getUsersByPlanCount(plan)
    return response.data
  } catch (e) {
    console.log('Error: admin/fetchAdminUsersByPlanCount', e)
  }
})

export const fetchAdminUserPage = createAsyncThunk('admin/fetchAdminUserPage', async ({ page, limit, filter }: {
  page: number
  limit: number
  filter: string
}) => {
  try {
    const response = await adminApi.getAdminUserPage(page, limit, filter)
    return response.data
  } catch (e) {
    console.log('Error: admin/fetchAdminUserPage', e)
  }
})

interface deleteUserAsyncProps {
  token: string
  email: string
}
export const deleteUser = createAsyncThunk('admin/deleteUser', async (email: string) => {
  try {
    await adminApi.deleteUser(email)
  } catch (e) {
    console.log('Error: admin/deleteUser', e)
  }
})

export const fetchUserAquiringStats = createAsyncThunk('admin/fetchUserAquiringStats', async () => {
  try {
    const response = await adminApi.getUserAquiringStats()
    return response.data
  } catch (e) {
    console.log('Error: admin/fetchUserAquiringStats', e)
  }
})
function removeUser(array: Array<UserListItem>, identifier: string): Array<UserListItem> {
  const index = array.map((user) => user.email).indexOf(identifier)
  if (index > -1) {
    array.splice(index, 1)
  }
  return array
}

export const runUserProcess = createAsyncThunk('admin/runUserProcess', async () => {
  try {
    await adminApi.getRunUserProcess()
  } catch (e) {
    console.log('Error: admin/runUserProcess', e)
  }
})

const adminSlice = createSlice({
  name: 'admin',
  initialState: defaultState.admin,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminUserCount.pending, (admin) => {
        admin.userCount.status = 'loading'
      })
      .addCase(fetchAdminUserCount.fulfilled, (admin, action) => {
        admin.userCount.status = 'idle'
        admin.userCount.value = action.payload
      })
      .addCase(fetchAdminUserCount.rejected, (admin) => {
        admin.userCount.status = 'failed'
        admin.userCount.value = null
      })
      .addCase(fetchAdminTodaysEmailCount.pending, (admin) => {
        admin.todaysEmailCount.status = 'loading'
      })
      .addCase(fetchAdminTodaysEmailCount.fulfilled, (admin, action) => {
        admin.todaysEmailCount.status = 'idle'
        admin.todaysEmailCount.value = action.payload
      })
      .addCase(fetchAdminTodaysEmailCount.rejected, (admin) => {
        admin.todaysEmailCount.status = 'failed'
        admin.todaysEmailCount.value = null
      })
      .addCase(fetchAdminUsersByPlanCount.pending, (admin) => {
        admin.paidUserCount.status = 'loading'
      })
      .addCase(fetchAdminUsersByPlanCount.fulfilled, (admin, action) => {
        if (action.meta.arg.plan === 'PAID') {
          admin.paidUserCount.status = 'idle'
          admin.paidUserCount.value = action.payload
        }
      })
      .addCase(fetchAdminUsersByPlanCount.rejected, (admin) => {
        admin.paidUserCount.status = 'failed'
        admin.paidUserCount.value = null
      })
      .addCase(fetchAdminTodaysUserCount.pending, (admin) => {
        admin.todaysUserCount.status = 'loading'
      })
      .addCase(fetchAdminTodaysUserCount.fulfilled, (admin, action) => {
        admin.todaysUserCount.status = 'idle'
        admin.todaysUserCount.value = action.payload
      })
      .addCase(fetchAdminTodaysUserCount.rejected, (admin) => {
        admin.todaysUserCount.status = 'failed'
        admin.todaysUserCount.value = null
      })
      .addCase(fetchUserAquiringStats.pending, (admin) => {
        admin.userAquiringStats.status = 'loading'
      })
      .addCase(fetchUserAquiringStats.fulfilled, (admin, action) => {
        admin.userAquiringStats.status = 'idle'
        admin.userAquiringStats.value = action.payload
          .sort((a: UserAquiringStatsListItem, b: UserAquiringStatsListItem) =>
            (a.yearMonth > b.yearMonth) ? 1 : -1)
      })
      .addCase(fetchUserAquiringStats.rejected, (admin) => {
        admin.userAquiringStats.status = 'failed'
        admin.userAquiringStats.value = []
      })
  },
})

export const { } = adminSlice.actions;

export default adminSlice.reducer

export const selectAdminTodaysEmailCount = (state: State) => state.admin?.todaysEmailCount

export const selectAdminTodaysUserCount = (state: State) => state.admin?.todaysUserCount

export const selectAdminPaidUserCount = (state: State) => state.admin?.paidUserCount

export const selectAdminUserCount = (state: State) => state.admin?.userCount

export const selectAdminAquiringStats = (state: State) => state.admin?.userAquiringStats

