import { State } from "../../types";
import { selectIsAuthenticated } from "../auth/authSlice";
import { CookiesConsent } from "./CookiesConsentModalContent";
import { useSelector } from 'react-redux'

export const readCookiesConsentFromLocalStorage = (): CookiesConsent | null => {
    const cookiesConsentString = localStorage.getItem('acv-cookies-consent')
    const storedCookiesConsent: CookiesConsent | null = cookiesConsentString ? JSON.parse(cookiesConsentString) : null
    return storedCookiesConsent
}

export const writeCookiesConsentToLocalStorage = (cookiesConsent: CookiesConsent) => {
    localStorage.setItem('acv-cookies-consent', JSON.stringify(cookiesConsent))
}

export const clearCookiesConsentFromLocalStorage = () => {
    localStorage.removeItem('acv-cookies-consent')
}

export const isCookiesConsentCollected = () => {
    const localStorageCookiesConsent = readCookiesConsentFromLocalStorage()
    const user = useSelector((state: State) => state.user.value)
    const cookiesResponseCollected = user?.cookiesConsentCollected;
    const isAuthenticated = useSelector(selectIsAuthenticated)
    console.log('## isCookiesConsentCollected', { local: localStorageCookiesConsent?.cookiesConsentCollected, cookiesResponseCollected, user })
    if (isAuthenticated) {
        console.log('isCookiesConsentCollected 1', localStorageCookiesConsent?.cookiesConsentCollected && cookiesResponseCollected)
        return localStorageCookiesConsent?.cookiesConsentCollected && cookiesResponseCollected
    } else {
        console.log('isCookiesConsentCollected 2', localStorageCookiesConsent?.cookiesConsentCollected)
        return localStorageCookiesConsent?.cookiesConsentCollected
    }
}