import React from 'react'
import AppRouter from './routers/AppRouter'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store/createStore'
import 'normalize.css/normalize.css'
import './styles/styles.scss'
import { IconContext } from '@react-icons/all-files/lib/cjs/iconContext'
import Modal from './features/common/Modal'
import CookieConsentModalContent from './features/user/CookiesConsentModalContent'

const jsx = (
  <IconContext.Provider value={{ className: 'acv-icon' }}>
    <Provider store={store}>
      <AppRouter />
      <Modal Component={<CookieConsentModalContent />} />
    </Provider>
  </IconContext.Provider>
)

ReactDOM.render(jsx, document.getElementById('app'))
