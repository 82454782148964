import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import publicCvApi from './publicCvApi'
import { Cv, PublicCvSlice, State, } from '../../types'
import defaultState from '../../store/defaultState'

interface FetchCvByUsernameResponse {
  data: Cv,
  code: number
}

export const fetchCvByUsername = createAsyncThunk('publicCv/fetchCvByUsername', async (username: string, { dispatch }) => {
  try {
    const response = await publicCvApi.getCvByUsername(username)
    return {
      data: response.data,
      code: response.status
    }
  } catch (e) {
    console.log('Error: publicCv/fetchCvByUsername', e)
    dispatch(publicCvStatusCode({
      data: null,
      code: e.response.status
    }))
    throw e
  }
})


const publicCvSlice = createSlice({
  name: 'public-cv',
  initialState: defaultState.cv,
  reducers: {
    publicCvStatusCode: (publicCv: PublicCvSlice, action: PayloadAction<FetchCvByUsernameResponse>) => {
      publicCv.value = action.payload.data
      publicCv.code = action.payload.code
      publicCv.status = 'failed'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCvByUsername.fulfilled, (publicCv: PublicCvSlice, action: PayloadAction<FetchCvByUsernameResponse>) => {
        publicCv.status = 'idle'
        publicCv.value = action.payload.data
        publicCv.code = action.payload.code
      })
      .addCase(fetchCvByUsername.pending, (publicCv: PublicCvSlice) => {
        publicCv.status = 'loading'
      })
      .addCase(fetchCvByUsername.rejected, (publicCv: PublicCvSlice) => {
        publicCv.value = null
        publicCv.status = 'failed'
      })
  },
})

export const { publicCvStatusCode } = publicCvSlice.actions;

export default publicCvSlice.reducer

export const selectPublicCv = (state: State) => state.publicCv?.value
export const publicCvIsLoading = (state: State) => state.publicCv?.status == 'loading'
export const selectPublicCvCode = (state: State) => state.publicCv?.code


