import { signUp } from '../features/user/userSlice'
import { State } from '../types'
const defaultState: State = {
  admin: {
    userCount: {
      status: 'idle',
      value: null,
    },
    todaysEmailCount: {
      status: 'idle',
      value: null,
    },
    todaysUserCount: {
      status: 'idle',
      value: null,
    },
    paidUserCount: {
      status: 'idle',
      value: null,
    },
    userAquiringStats: {
      status: 'idle',
      value: [],
    },
  },
  auth: {
    username: null,
    token: null,
    activationId: null
  },
  publicCv: {
    status: 'idle',
    value: null,
    code: null
  },
  cv: {
    status: 'idle',
    value: null,
    code: null
  },
  user: {
    status: 'idle',
    value: null,
    signUp: {
      requested: null,
      success: null
    }
  },
  common: {
    language: {
      status: 'idle',
      value: null
    },
    toasts: [],
    cookieConsentCollected: false
  },
} as State

export default defaultState
