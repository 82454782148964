import { axiosApiInstance } from '../common/axios'
import { Cv, Experience, Photo, Video } from '../../types'

const VIEW_PATH = '/api/cv/view/'
const VIEW_CV_PUBLIC_PATH = '/api/cv/view/public/'
const SAVE_PATH = '/api/cv'
const PHOTO_PATH = '/photo'
const PHOTOS_PATH = '/photos'
const PRIMARY_PHOTO_PATH = '/primary'
const EXPERIENCE_PATH = '/experience'
const EXPERIENCES_PATH = '/experiences'
const VIDEO_PATH = '/video'
const VIDEOS_PATH = '/videos'


const getCv = async () => {
    return await axiosApiInstance.get(
        VIEW_PATH
    )
}

const postExperience = async (cvId: number, experience: Experience) => {
    return await axiosApiInstance.post(SAVE_PATH + '/' + cvId + EXPERIENCE_PATH, experience)
}

const putExperiences = async (cvId: number, experiences: Experience[]) => {
    return await axiosApiInstance.put(SAVE_PATH + '/' + cvId + EXPERIENCES_PATH, experiences)
}

const putExperience = async (cvId: number, experience: Experience) => {
    return await axiosApiInstance.put(SAVE_PATH + '/' + cvId + EXPERIENCE_PATH + '/' + experience.id, experience)
}

const deleteExperience = async (cvId: number, experienceId: number) => {
    return await axiosApiInstance.delete(SAVE_PATH + '/' + cvId + EXPERIENCE_PATH + '/' + experienceId
    )
}

const putPhotos = async (cvId: number, photos: Photo[]) => {
    return await axiosApiInstance.put(SAVE_PATH + '/' + cvId + PHOTOS_PATH, photos)
}

const putSelectPrimaryPhoto = async (cvId: number, photoId: number) => {
    return await axiosApiInstance.put(SAVE_PATH + '/' + cvId + PHOTO_PATH + '/' + photoId + PRIMARY_PHOTO_PATH, {})
}

const deletePhoto = async (cvId: number, photoId: number) => {
    return await axiosApiInstance.delete(SAVE_PATH + '/' + cvId + PHOTO_PATH + '/' + photoId
    )
}

const postPhoto = async (cvId: number, formData: FormData) => {
    return await axiosApiInstance.post(SAVE_PATH + '/' + cvId + PHOTO_PATH, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

const saveCv = async (cv: Cv) => {
    return await axiosApiInstance.post(
        SAVE_PATH,
        cv
    )
}

const deleteVideo = async (cvId: number, videoId: number) => {
    return await axiosApiInstance.delete(SAVE_PATH + '/' + cvId + VIDEO_PATH + '/' + videoId
    )
}

const putVideos = async (cvId: number, videos: Video[]) => {
    return await axiosApiInstance.put(SAVE_PATH + '/' + cvId + VIDEOS_PATH, videos)
}

const postVideo = async (cvId: number, video: Video) => {
    return await axiosApiInstance.post(SAVE_PATH + '/' + cvId + VIDEO_PATH, video)
}

export default {
    getCv,
    postExperience,
    putExperiences,
    putExperience,
    deleteExperience,
    putPhotos,
    putSelectPrimaryPhoto,
    deletePhoto,
    postPhoto,
    saveCv,
    deleteVideo,
    putVideos,
    postVideo
}