import { Plan } from '../../types'
import { axiosApiInstance } from '../common/axios'

const GET_ACV_ADMIN_PATH = '/api/admin/acv'
const GET_ACV_ADMIN_USERS_PATH = GET_ACV_ADMIN_PATH + '/users'
const GET_ACV_ADMIN_STRIPE_EVENTS_PATH = GET_ACV_ADMIN_PATH + '/stripe-events'
const GET_ACV_ADMIN_EMAILS_PATH = GET_ACV_ADMIN_PATH + '/emails'

const enrollUser = async (userId: number, plan: Plan) => {
    return await axiosApiInstance.put(
        `/api/admin/acv/users/${userId}/${plan}`,
        {}
    )
}

const getTodaysEmailCount = async () => {
    return await axiosApiInstance.get(
        '/api/admin/acv/emails/count/today',
        {}
    )
}

const getTodaysUserCount = async () => {
    return await axiosApiInstance.get(
        '/api/admin/acv/users/count/today',
        {}
    )
}

const getUsersByPlanCount = async (plan: string) => {
    return await axiosApiInstance.get(
        '/api/admin/acv/users/count/filter' + '?plan=' + encodeURIComponent(plan),
        {}
    )
}

const getAdminUserCount = async () => {
    return await axiosApiInstance.get(
        GET_ACV_ADMIN_USERS_PATH + '/count',
        {}
    )
}

const getAdminUserPage = async (page: number, perPage: number, filter?: string) => {
    return await axiosApiInstance.get(
        GET_ACV_ADMIN_USERS_PATH + '?page=' + String(page) + '&limit=' + String(perPage) + (filter ? '&filter=' + encodeURIComponent(filter) : ''),
        {}
    )
}

const getStripeEventsPage = async (page: number, perPage: number, filter?: string) => {
    return await axiosApiInstance.get(
        GET_ACV_ADMIN_STRIPE_EVENTS_PATH + '?page=' + String(page) + '&limit=' + String(perPage) + (filter ? '&filter=' + encodeURIComponent(filter) : ''),
        {}
    )
}

const getAdminEmailsPage = async (page: number, perPage: number, filter?: string) => {
    return await axiosApiInstance.get(
        GET_ACV_ADMIN_EMAILS_PATH + '?page=' + String(page) + '&limit=' + String(perPage) + (filter ? '&filter=' + encodeURIComponent(filter) : ''),
        {}
    )
}

const deleteUser = async (email: string) => {
    return await axiosApiInstance.post(
        GET_ACV_ADMIN_USERS_PATH + '/delete/' + email,
        {}
    )
}

const getUserAquiringStats = async () => {
    const res = await axiosApiInstance.post(
        GET_ACV_ADMIN_USERS_PATH + '/aquiringStats',
        {}
    )
    return res
}

const getRunUserProcess = async () => {
    return await axiosApiInstance.get(
        '/api/scheduled/core/run',
        {}
    )
}

export default {
    enrollUser,
    getUsersByPlanCount,
    getStripeEventsPage,
    getTodaysEmailCount,
    getTodaysUserCount,
    getAdminUserCount,
    getAdminUserPage,
    deleteUser,
    getUserAquiringStats,
    getRunUserProcess,
    getAdminEmailsPage
}